<template>
<div>
    <div class="row tgju-widgets-row dashboard-widgets-technical-list" ref="top">
        <PublicTechnicalList :filter="selected" :hot="selectedHot" :my_technicals="selectedMyTechnicals" :time="sortByTime" :market="selectedMarket" :bookmark="bookmark" :key="componentReload"></PublicTechnicalList>
    </div>
</div>
</template>

<style>
    html ,
    body[data-route="TgjuForumTechnicals"]{
    height: 100%;
    }
    body[data-route="TgjuForumTechnicals"] .gotop{
        display:none;
    }
    body[data-route="TgjuForumTechnicals"] .p-5.d-flex.justify-content-center {
        padding: 0 !important;
        margin-top: 10px !important;
    }
    body[data-route="TgjuForumTechnicals"] #main {
        background: #fff url('https://static.tgju.org/views/default/images/widget-pattern.png?004') !important;
        background-size: auto;
        font-family: iranyekan !important;
        background-size: 200% !important;
        height: 100%;
        overflow: auto;
    }
    body[data-route="TgjuForumTechnicals"] .col-12.col-lg-6.col-xl-6.col-xxl-4 {
        padding: 0 !important;
        border-radius: 0 !important;
    }
    body[data-route="TgjuForumTechnicals"] .dashboard-main-box {
        border-top: 1px solid #d5d5d5;
        padding: 0 !important;
    }
    body[data-route="TgjuForumTechnicals"] .tgju-widget.light.post.mb-3 {
        margin-bottom: 0 !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #d5d5d5 !important;
        box-shadow: none !important;
    }

</style>

<script>

// این ویو برای تحلیل های عمومی مورد استفاده قرار میگیرد
import PublicTechnicalList from '@/components/Technical/PublicTechnicalList.vue'
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'TgjuForumTechnicals',
    components: {
        PublicTechnicalList,
    },
    data: function () {
        return {
            showBackButton: false,
            componentReload: 0,
            selected: 'newest',
            selectedHot: '',
            sortByTime: 'all',
            selectedMarket: 'all',
            selectedMyTechnicals: false,
            bookmark: false,
            hot: false,
            widget: {},
            data: {},
        }
    },
    mounted () {
                        $("body").append(`
                        <style>
                            footer , header , .list-box-index , .index-row-items , bar-menu-dashboard-box
                            .bar-menu , .footer-quickview , .mobile-hide , .bottom-left-ad , .bottom-right-ad , .bar-menu-dashboard-box {
                                display: none !important;
                                }
                            body {
                                margin: 0px !important;
                                padding: 0px !important;
                                background: white !important;
                            }
                            .widgets {
                                border: none !important;
                            }
                        </style>
                        `);
    },
    methods: {
        // این متد جهت باز کردن یک مدال برای افزودن تحلیل جدید استفاده می شود
        open_new_technical_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_NewTechnical_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'NewTechnical.vue',
                config: {
                    title: 'تحلیل جدید',
                    icon: 'uil uil-plus-circle with-icon',
                    smallModal: false,
                    data: {
                        // جهت ریفرش کردن مدال که از داده های قدیمی پاک شود
                        refresh: Math.random(),
                    },
                }
            };

            this.$root.$emit('open_modal3', modal_data);

            // وقتی روی دکمه بستن مدال کلیک شد
            // در کال بک فانشکن لیست را آپدیت میکنیم . 
            this.$root.$on(callbackEvent, (response) => {
                this.refresh++;
            });

            $('body').addClass('show-modal3');
        },
        // این متد یک اتریبوبت را کامپوننت لیست تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل هایی که بو مارک شده اند
        selectBookmark() {
            if (this.bookmark) {
                this.bookmark = false;
            } else if (!this.bookmark) {
                this.bookmark = true;
            }
            this.componentReload = Math.random();
        },
        // این متد یک اتریبوبت را کامپوننت لیست تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        // قدیمی ترین ، محبوب ترین ، جدیدترین
        selectFilter() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        selectTime() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        // این متد یک اتریبوبت را کامپوننت مارکت تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        selectMarket() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        // متد برای نمایش داغ ترین یا همان محبوب ترین تحلیل ها
        selectHot() {
            if (this.hot) {
                this.selectedHot = '';
                this.hot = false;
            } else if (!this.hot) {
                this.selectedHot = 'popular';
                this.hot = true;
            }
            this.componentReload = Math.random();
        },
        selectMyTechnicals() {
            if (this.selectedMyTechnicals) {
                this.selectedHot = '';
                this.selectedMyTechnicals = false;
            } else if (!this.selectedMyTechnicals) {
                this.selectedHot = 'popular';
                this.selectedMyTechnicals = true;
            }
            this.componentReload = Math.random();

        },
        // دکمه بازگشت
        back() {
            this.$router.push({ name : 'NewTechnical' });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Technical.vue',
                title: 'تحلیل‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
